import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import uuid from "react-uuid";

const CreateSpeicalRoom = (props: any) => {
    let navigate = useNavigate();
    let params = useParams();
    const [user, setUser] = useState<any>({});
    const id = params.id;
    console.log(params);
    console.log(params.token)

    useEffect(() => {
        const test = async () => {
            localStorage.setItem('token', JSON.stringify(params.token));
            // await axios.post('http://127.0.0.1:8000/api/check-token', { token: params.token })
            await axios.post('https://app.corpus.study/api/check-token', { token: params.token })
                .then(response => setUser(response.data))
                
        }
        test();
    }, [])

    useEffect(() => {
        if (user.role) {
            localStorage.setItem('user', JSON.stringify(user))
            if (user.role === 'Öğretmen') {
                localStorage.setItem('type', '0');
                navigate(`/meet/${id}`);
            }else if (user.role === 'Uygulama Yöneticisi') {
                localStorage.setItem('type', '0');
                navigate(`/meet/${id}`);
            }else if (user.role === 'Meet Yetkilisi') {
                localStorage.setItem('type', '0');
                navigate(`/meet/${id}`);
            }else {
                console.log('ogrenci');
                localStorage.setItem('type', '1');
                navigate(`/meet/${id}`);
            }
        }
    }, [user])



    return (
        <div className="container text-center mt-5">

        </div>
    );
};

export default CreateSpeicalRoom;
