import { useState } from 'react';
import { Avatar, List } from 'antd';
import '../icon.css';
import '../style.css';

interface Props {
    title: string;
    message: string,
    hour: string;
}

function formatDateFromTimestamp(timestamp: any) {
    const date = new Date(timestamp);
    return date.toLocaleString();
}

const ChatUsers: React.FC<Props> = ({ title, message, hour }) => {
    const [showFullMessage, setShowFullMessage] = useState(false);

    const shortMessage = message.slice(0, 850);
    const fullMessage = message.slice(850);

    const handleReadMore = () => {
        setShowFullMessage(true);
    };

    return (
        <div className='chat-users'>
            <List.Item className='chat-user-item'>
                <p className='messageboxp'>
                    <span>
                        <Avatar style={{ marginRight: '4px', marginBottom: '5px' }}>
                            <div className='avatar-char'>
                                {title.charAt(0)}
                            </div>
                        </Avatar>
                    </span>
                    <p className='name-surname'>{title}</p>
                </p>
                <p className='messagebox'>
                    {showFullMessage ? message : shortMessage}
                    {message.length > 850 && !showFullMessage && (
                        <button onClick={handleReadMore}>Devamını Oku</button>
                    )}
                    {showFullMessage && (
                        <div>{fullMessage}</div>
                    )}
                </p>
                <span className='hour'>{formatDateFromTimestamp(hour)}</span>
            </List.Item>
        </div>
    );
};

export default ChatUsers;
