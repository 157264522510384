import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import '../style.css'

import { Layout } from 'antd';
import Screen from './Screen';

interface usersArray {
    userId: string,
    info: {
        peerid: any;
        id: number,
        name: string,
        mic: boolean,
        sound: boolean,
        camera: boolean,
        screen: boolean,
        type: number,
        status: string,
        class: number,
        school: string,
        userCorpusId: number,
    }
}

interface Props {
    users: Array<usersArray>,
    collapsed: boolean,
    type: number,
    clientId: any,
}

const Grid: React.FC<Props> = ({ users, collapsed, type, clientId }) => {
    console.log('userss', users);
    const [length, setLength] = useState(0);

    useEffect(() => {
        setLength(0)
        users.forEach((user: any) => {
            if (user.info.id === clientId && type != 2) {
                setLength(length => length + 1);
                console.log('length', length)
            } else {
            }
            if (type === 1) {
                if (user.info.type === 0) {
                    setLength(length => length + 1);
                    console.log('length', length)

                } else {
                }
            }
            if (type === 0) {
                if (user.info.id !== clientId) {
                    if(user.info.type != 2){
                    setLength(length => length + 1);
                    console.log('length', length)
                }
                } else {
                }
            }
            if (type === 2) {
                if (user.info.id !== clientId) {
                    setLength(length => length + 1);
                    console.log('length', length)
                } else {
                }
            }
        })

    }, [users])


    return (
        <Layout id='grid-screens' className={'grid-screens'}>
            {length <= 1 ?
                <>
                    <Row style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                        {(users.map((user) => (
                            // <Col span={12} style={{ height: '65%' }} key={user.info.id}>
                            //     <Screen title={user.info.name + ' | ' + user.info.school + ' | ' + user.info.class_id + 4} screenCount={length} peerID={user.info.peerid} collapsed={collapsed}/>
                            // </Col>
                            type == 1 && user.info.type == 0 ?
                                <Col span={12} style={{ height: '65%' }} key={user.info.id}>
                                    <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                </Col> : ((type == 0 && user.info.type != 2) || type == 2 && user.info.type != 2 ? <Col span={12} style={{ height: '65%' }} key={user.info.id}>
                                    <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                </Col> : (clientId == user.info.id && type != 2 ? <Col span={12} style={{ height: '65%' }} key={user.info.id}>
                                    <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                </Col> : ''))
                        )))}
                    </Row>
                </>
                :
                length <= 2 ?
                    <>
                        <Row style={{ height: '100%', display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            {(users.map((user) => (
                                // <Col span={12} style={{ height: '65%' }} key={user.info.id}>
                                //     <Screen title={user.info.name + ' | ' + user.info.school + ' | ' + user.info.class_id + 4} screenCount={length} peerID={user.info.peerid} collapsed={collapsed}/>
                                // </Col>
                                clientId == user.info.id && type != 2 ?
                                    <Col span={12} style={{ height: '65%' }} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : ((type == 0 && user.info.type != 2) || type == 2 && user.info.type != 2 ? <Col span={12} style={{ height: '65%' }} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : (type == 1 && user.info.type == 0 ? <Col span={12} style={{ height: '65%' }} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : ''))
                            )))}
                        </Row>
                    </>
                    :
                    length <= 4 ?
                        <Row style={{ height: '100%', marginTop: '10px' }}>
                            {(users.map((user) => (
                                // <Col span={12} key={user.info.id}>
                                //     <Screen title={user.info.name + ' | ' + user.info.school + ' | ' + user.info.class_id + 4} screenCount={length} peerID={user.info.peerid} collapsed={collapsed}/>
                                // </Col>
                                clientId == user.info.id && type != 2 ?
                                    <Col span={12} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : ((type == 0 && user.info.type != 2) || type == 2 && user.info.type != 2 ? <Col span={12} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : (type == 1 && user.info.type == 0 ? <Col span={12} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : ''))
                            )))}

                        </Row>
                        :
                        users.length <= 9 ?
                        <Row style={{ height: '100%', marginTop: '10px' }}>
                            {(users.map((user) => (
                                clientId == user.info.id && type != 2 ?
                                    <Col span={8} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : ((type == 0 && user.info.type != 2) || type == 2 && user.info.type != 2 && user.info.type != 2 ? <Col span={8} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : (type == 1 && user.info.type == 0 ? <Col span={8} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : ''))
                                // <Col span={8} key={user.info.id}>
                                //     <Screen title={user.info.name + ' | ' + user.info.school + ' | ' + user.info.class_id + 4} screenCount={length} peerID={user.info.peerid} collapsed={collapsed}/>
                                // </Col>
                            )))}
                        </Row>
                        : users.length <= 15 ?
                        <Row style={{ height: '100%', marginTop: '10px' }}>
                            {(users.map((user) => (
                                clientId == user.info.id && type != 2 ?
                                    <Col span={6} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : ((type == 0 && user.info.type != 2) || type == 2 && user.info.type != 2 ? <Col span={6} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : (type == 1 && user.info.type == 0 ? <Col span={6} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : ''))
                                // <Col span={8} key={user.info.id}>
                                //     <Screen title={user.info.name + ' | ' + user.info.school + ' | ' + user.info.class_id + 4} screenCount={length} peerID={user.info.peerid} collapsed={collapsed}/>
                                // </Col>
                            )))}
                        </Row>
                        :
                        // users.length <= 10 ?
                        <Row style={{ height: '100%', marginTop: '10px' }}>
                            {(users.map((user) => (
                                clientId == user.info.id && type != 2 ?
                                    <Col span={4} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : ((type == 0 && user.info.type != 2) || type == 2 && user.info.type != 2 ? <Col span={4} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : (type == 1 && user.info.type == 0 ? <Col span={4} key={user.info.id}>
                                        <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                    </Col> : ''))
                                // <Col span={8} key={user.info.id}>
                                //     <Screen title={user.info.name + ' | ' + user.info.school + ' | ' + user.info.class_id + 4} screenCount={length} peerID={user.info.peerid} collapsed={collapsed}/>
                                // </Col>
                            )))}
                        </Row>
                //     : 'limit aşıldı'
            }

        </Layout >
    );
};

export default Grid;