import React from 'react'
import { Button, Input, Row, Col, Form, Typography, Layout } from 'antd'
import '../invite.css';
import logo from "./../logo.svg";
import imageMeet from "./../icons/5.png";

import { useNavigate, useParams } from 'react-router-dom';

export default function InviteMeet() {
    const { Title } = Typography;
    let navigate = useNavigate();
    let params = useParams();
    const id = params.roomID;

    function randomNumberInRange(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const onFinish = (values: any) => {

        const user = {
            "id": randomNumberInRange(2, 9999),
            "name": values.firstname,
            "surname": values.lastname,
            "role": "Öğrenci"
        }
        localStorage.setItem('type', '1');
        localStorage.setItem('user', JSON.stringify(user));
        navigate(`/meet/${id}`);
        console.log(user);

    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Layout>
            <Row style={{ height: '100vh' }}>
                <Col span={12} style={{ background: "white", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ width: '100%', maxWidth: '350px', minWidth: '200px' }}>
                        <Title level={2} style={{ textAlign: 'center', marginBottom: '2rem' }}>
                            CORPUS.STUDY
                            MEET GİRİŞİ
                        </Title>

                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="firstname"
                                rules={[{ required: true, message: 'İsim girmelisiniz!' }]}
                            >
                                <Input placeholder="İsim" />
                            </Form.Item>

                            <Form.Item
                                name="lastname"
                                rules={[{ required: true, message: 'Soyisim girmelisiniz!' }]}
                            >
                                <Input placeholder="Soyisim" />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: '#229ef7' }}>
                                    Toplantıya Katıl
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
                <Col span={12} style={{ background: "#379bff", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ marginBottom: "10px" }} src={logo} alt="Corpus Logo" height={65} />
                    <img src={imageMeet} alt="placeholder" style={{ marginBottom: "10px", maxHeight: '300px' }} />
                    <Title level={2} style={{ color: 'white' }}>Anlayarak Hızlı Okuma</Title>
                    <Title level={5} style={{ color: 'black', fontWeight: 'inherit' }}>2023 © Corpus Study Tüm hakları Artcom Ltd. Şti.'ne aittir. Ver. 2.1 / 2021</Title>
                </Col>
            </Row>
        </Layout>
    )
}
