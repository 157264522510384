import { Col, Divider, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import '../icon.css';
import '../style.css';
import iconFullscreen from '../icons/fscreen.png'
import iconInfo from '../icons/icon-info.png'
import iconMicOpen from '../icons/icon-mic-open.png'
import iconMicClose from '../icons/icon-mic-close.png'

import { Popover } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface Props {
  title: String,
  screenCount?: any,
  peerID: any,
  collapsed: boolean,
  status: string,
  type: number,
  mic: boolean,
  id:number,
}

const Screen: React.FC<Props> = ({ title, screenCount, peerID, collapsed, status, type, mic,id }) => {
  const [reverseContent, setReverseContent] = useState(true);
  const [data, setData] = useState('');
  let params = useParams();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);

  useEffect(() => {
    var a: any = document.getElementById(peerID + 'screen');
    var b: any = document.getElementById(peerID + 'cam');

    const videoA = a.querySelector("video");
    const videoB = b.querySelector("video");

    if (videoA && videoB) {
      const srcA = videoA.srcObject;
      const srcB = videoB.srcObject;
      const idA = videoA.id;
      const idB = videoB.id;
      videoA.srcObject = srcB;
      videoB.srcObject = srcA;
      videoA.id = idB;
      videoB.id = idA;
      videoA.play();
      videoB.play();
    } else if (videoB) {
      if (a.childElementCount === 0) {
        a.appendChild(videoB);
        videoB.play();
      } else {
        b.appendChild(videoB);
        videoB.play();
      }
    }
  }, [reverseContent]);

  const [screenContentEmpty, setScreenContentEmpty] = useState(false);
  const screenContentRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    screenContentRef.current = document.getElementById(peerID + 'screen');
    setScreenContentEmpty(!screenContentRef.current?.hasChildNodes());
  });

  useEffect(() => {
    setReverseContent(!reverseContent);
  }, [screenContentEmpty])

  const handleStatusPopup = async () => {
    setShowStatusPopup(!showStatusPopup);
    await axios.get(`https://app.corpus.study/api/get-user-last-data/${id}` )
    .then(response => setData(response.data));
  }
  return (
    <div className={screenCount <= 2 ? "screen-container" : "screen-container-3"} >
      {isFullScreen ? <>
        <div className={collapsed ? 'screen full-screen-collapsed' : 'screen full-screen'}>
          {status !== '' && type === 0 || type === 2 ?
            <Popover content={<div style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: data }} />} trigger="click" >
              <img src={iconInfo} alt="" style={{ left: '5px', top: '5px', position: 'absolute', zIndex: '105' }} width={24} height={24} onClick={handleStatusPopup} />
            </Popover>
            : ''
          }

          <img src={iconFullscreen} alt="" style={{ right: '5px', top: '5px', position: 'absolute', zIndex: '100' }} width={24} height={24} onClick={() => setIsFullScreen(!isFullScreen)} />
          <img src={mic ? iconMicOpen : iconMicClose} alt="" style={{ right: '5px', top: '35px', position: 'absolute', zIndex: '100' }} width={24} height={24} />
          <div id={peerID + 'screen'} className='screen-content'>
          </div>
          <div id={peerID + 'mic'} className='mic-content'>
          </div>
          <div className='title-camera-container'>
            <div className='name-tag'>
              <span className='name-tag-title'>{title}</span>
            </div>
            <div onClick={() => setReverseContent(!reverseContent)}
              className={collapsed ? "cam-full" : 'cam-full-collapsed'}
              id={peerID + 'cam'}>
            </div>
          </div>
        </div>
      </>
        : <>
          <div className={"screen"} >
            {status !== '' && type === 0 || type === 2 ?
              <Popover content={<div style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: data }} />} trigger="click">
                <img src={iconInfo} alt="" style={{ left: '5px', top: '5px', position: 'absolute', zIndex: '105' }} width={24} height={24} onClick={handleStatusPopup} />
              </Popover>
              : ''
            }
            <img src={iconFullscreen} alt="" style={{ right: '5px', top: '5px', position: 'absolute', zIndex: '100' }} width={24} height={24} onClick={() => setIsFullScreen(!isFullScreen)} />
            <img src={mic ? iconMicOpen : iconMicClose} alt="" style={{ right: '5px', top: '35px', position: 'absolute', zIndex: '100' }} width={24} height={24} />
            <div id={peerID + 'screen'} className='screen-content' >
            </div>
            <div id={peerID + 'mic'} className='mic-content'>
          </div>
            <div className='title-camera-container'>
              <div className='name-tag'>
                <span className='name-tag-title'>{title}</span>
              </div>
              <div onClick={() => setReverseContent(!reverseContent)}
                className={screenCount <= 2 ? (collapsed ? "cam" : 'cam-collapsed') : screenCount <= 4 ? (collapsed ? "cam-4" : 'cam-collapsed') : screenCount <= 6 ? (collapsed ? "cam-6" : 'cam-6-collapsed') : (collapsed ? "cam-10" : 'cam-10-collapsed')}
                id={peerID + 'cam'}>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default Screen