import React,{useEffect} from 'react';
import { Routes, Route, BrowserRouter, Link, Navigate } from "react-router-dom";
import CreateRoom from './pages/CreateRoom';
import Room from './pages/Room';
import Loby from './pages/Loby';
import CreateSpeicalRoom from './pages/CreateSpecialRoom';
import SpecialRoom from './pages/SpecialRoom';
import InviteMeet from './pages/InviteMeet';
import { Redirect } from './components/Redirect';

function App() {
  useEffect(() => {
    document.title = 'Corpus Study Meet'
  }, [])
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Redirect />} />

        <Route path="/welcome/:id/:token" element={<CreateRoom />} />
        <Route path="/welcome-meet/:id/:token" element={<CreateSpeicalRoom />} />
        <Route path="/meet/:roomID" element={<SpecialRoom />} />
        <Route path="/room/:roomID" element={<Room />} />
        <Route path="/lobby/:roomID" element={<Loby />} />
        <Route path='/invite/:roomID' element={<InviteMeet />} />
        <Route path="/a" element={<Link to="https://www.google.com/" />} />
        {/* <Route
          path="/a"
          element={
            window.location.href = "https://google.com"
          }
        /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
