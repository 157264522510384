import React, { useState } from 'react';
import { Avatar, Card, Col, Row, Space, Popconfirm } from 'antd';
import '../icon.css';
import '../style.css';


const { Meta } = Card;
interface Props {
    name: String,
    changeMic: any
    changeScreen: any
    changeCamera: any
    changeSound: any
    kick: any
    sendPrivateMessage: any
    mic: boolean,
    voice: boolean
    cam: boolean,
    screen: boolean,
    user_id: any,
    client_id: any,
    type: number,
    isLoby: boolean
    userType : any,
}
const User: React.FC<Props> = ({ name, changeMic, changeScreen, changeCamera, changeSound, kick, sendPrivateMessage, mic, cam, screen, client_id, user_id, type, voice, isLoby, userType }) => {

    // const [voice, setVoice] = useState(true);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);

        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    return (
        <div>
            {userType != 2 ?
        < Card className='user-card' style={{ background: '#292B3B' }}>
            <Row justify="space-between">
                <Col span={14}>
                    <Meta className='user-card-box'
                        avatar={<Avatar >
                            <div className='avatar-char'>
                                {name.charAt(0)}
                            </div>
                        </Avatar>}
                        title={<span style={{ color: '#C1C1C6' }}>{name}</span>}
                    />
                </Col>
                <Col span={10} style={{justifyContent:'end'}}>
                    {user_id === client_id ? <span style={{ color: '#C1C1C6' }}>(BEN)</span> :
                        (type === 1) ? <div></div> :
                            <Space size={5}>
                                {/* <span
                                    onClick={sendPrivateMessage}
                                    className={"icon-message icon-height"} >
                                </span> */}
                                <span
                                    onClick={cam ? '' : changeCamera}
                                    className={cam ? "icon-cam-enable icon-height" : "icon-cam-disable icon-height"} >
                                </span>
                                {isLoby ? ''
                                    : <span
                                        onClick={screen ? '' : changeScreen}
                                        className={screen ? "icon-screen-enable icon-height" : "icon-screen-disable-red icon-height"} ></span>}

                                <span
                                    onClick={mic ? '' : changeMic}
                                    className={mic ? "icon-mic-enable icon-height" : "icon-mic-disable icon-height"} >
                                </span>
                                <span
                                    onClick={changeSound}
                                    className={voice ? "icon-voice-enable icon-height" : "icon-voice-disable icon-height"} >
                                </span>
                                <Popconfirm
                                    title="Dikkat!"
                                    description="Öğrenciyi dersten çıkartmak üzeresiniz!"
                                    open={open}
                                    onConfirm={kick}
                                    okButtonProps={{ loading: confirmLoading }}
                                    onCancel={handleCancel}
                                >
                                    <span
                                        onClick={showPopconfirm}
                                        className={"icon-kick icon-height"} >
                                    </span>
                                </Popconfirm>

                            </Space>
                    }
                </Col>
            </Row>
        </Card >
        : ""}

        </div>
    )
};

export default User;