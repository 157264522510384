import React, { useState, useEffect } from 'react'
import { Card, Space, Popover, Input } from 'antd';
import '../icon.css';
import '../style.css';
import { Socket } from 'socket.io-client';
import Peer from 'peerjs';
import { CopyOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom';
const { Meta } = Card;

interface Props {
    changeMessage: any;
    changeMyScreen: any;
    changeMyMic: any;
    socket: Socket;
    clientId: string;
    changeMyCamera: any;
    mic: any;
    screen: any;
    camera: any;
}

declare global {
    interface Window {
        localStream: any; // 👈️ turn off type checking
    }
}

const SpecialActionBar: React.FC<Props> = ({
    changeMessage,
    changeMyCamera,
    changeMyScreen,
    changeMyMic,
    clientId,
    socket,
    mic,
    screen,
    camera,
}) => {
    var type = JSON.parse(localStorage.getItem('type') || '');
    const [grid, setGrid] = useState(true);
    let params = useParams();
    // Add states for each button's disabled status
    const [cameraDisabled, setCameraDisabled] = useState(false);
    const [screenDisabled, setScreenDisabled] = useState(false);
    const [micDisabled, setMicDisabled] = useState(false);
    const [callEnd, setCallEnd] = useState(true);
    const [message, setMessage] = useState(true);
    const [settings, setSettings] = useState(true);
    const [invite, setInvite] = useState(true);
    const [leftArrow, setLeftArrow] = useState(true);
    const [righArrow, setRightArrow] = useState(true);
    const [copied, setCopied] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    // meet cagridan ayrilam methodu
    function leave() {
        window.location.href = 'https://app.corpus.study';
    }
    function raiseYourHand() {
        socket.emit('raiseYourHand', clientId);
    }

    // Helper function to handle button disabling
    const handleClick = (
        eventHandler: () => void,
        setDisabled: (disabled: boolean) => void
    ) => {
        eventHandler();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1760);
        setTimeout(() => {
            setButtonDisabled(true);
        }, 1200);
    };

    const copyToClipboard = () => {
        console.log('params', params);
        const inviteLink = `https://meet.corpus.study/invite/${params.roomID}`;
        navigator.clipboard.writeText(inviteLink);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 500);
    };

    const inviteContent = (
        <div style={{ display: 'flex' }}>
            <Input
                id="invite-link"
                value={`https://meet.corpus.study/invite/${params.roomID}`}
                readOnly
                className='input-link'
                style={{
                    width: '100%',
                    borderColor: copied ? 'limegreen' : undefined
                }}
            />

            <CopyOutlined onClick={copyToClipboard} style={{ marginTop: '10px', marginLeft: '10px', color: '#fff' }} />
        </div>
    );

    const [colorCam, setColorCam] = useState(false);
    const [colorScreen, setColorScreen] = useState(false);
    const [colorMic, setColorMic] = useState(false);
    const [colorHand, setColorHand] = useState(false);


    const setBordorColor = (id: number) => {
        if (id === 1) {
            setColorCam(true);
            setTimeout(() => {
                setColorCam(false);
            }, 200);
        }
        else if (id === 2) {
            setColorScreen(true);
            setTimeout(() => {
                setColorScreen(false);
            }, 200);
        }
        else if (id === 3) {
            setColorMic(true);
            setTimeout(() => {
                setColorMic(false);
            }, 200);
        }
        else if (id === 4) {
            setColorHand(true);
            setTimeout(() => {
                setColorHand(false);
            }, 200);
        }
    };

    return (
        <Card className="action-bar" style={{ background: '#292B3B', textAlign: 'center' }}>
            <Space size={15}>
                {/* {type == 0 ? (
                    <span
                        onClick={() => setGrid((prevMode) => !prevMode)}
                        className={grid ? 'icon-grid icon-action-bar' : 'icon-grid icon-action-bar'}
                    ></span>
                ) : (
                    ''
                )} */}

                <span
                    onClick={() => {
                        if (buttonDisabled) {
                            setButtonDisabled(false);
                            handleClick(changeMyCamera, setCameraDisabled);
                            setBordorColor(1);
                        }
                    }}
                    className={
                        camera
                            ? 'icon-cam-enable icon-action-bar'
                            : 'icon-cam-disable-white icon-action-bar'
                    }
                    style={{ cursor: 'pointer', pointerEvents: cameraDisabled ? 'none' : 'auto', borderColor: colorCam ? 'limegreen' : '' }}
                ></span>
                <span
                    onClick={() => {
                        if (buttonDisabled) {
                            setButtonDisabled(false);
                            handleClick(changeMyScreen, setScreenDisabled);
                            setBordorColor(2);
                        }
                    }}
                    className={
                        screen
                            ? 'icon-screen-enable icon-action-bar'
                            : 'icon-screen-disable icon-action-bar'
                    }
                    style={{ cursor: 'pointer', pointerEvents: screenDisabled ? 'none' : 'auto', borderColor: colorScreen ? 'limegreen' : '' }}
                ></span>
                <span
                    onClick={() => {
                        if (buttonDisabled) {
                            setButtonDisabled(false);
                            handleClick(changeMyMic, setMicDisabled);
                            setBordorColor(3);
                        }
                    }}
                    className={
                        mic
                            ? 'icon-mic-enable icon-action-bar'
                            : 'icon-mic-disable-white icon-action-bar'
                    }
                    style={{ cursor: 'pointer', pointerEvents: micDisabled ? 'none' : 'auto', borderColor: colorMic ? 'limegreen' : '' }}
                ></span>
                <span onClick={leave} className="icon-call-end icon-action-bar-call"></span>
                <span
                    onClick={changeMessage}
                    className={"icon-message icon-action-bar"}
                ></span>
                {/* {type == 0 ? (
                    <span
                        onClick={() => setSettings(prevMode => !prevMode)}

                        className={
                            settings
                                ? "icon-settings icon-action-bar-settings"
                                : "icon-settings icon-action-bar-settings"
                        }
                    ></span>
                ) : (
                    ""
                )} */}
                {type == 0 || type == 2 ? (
                    <Popover
                        content={inviteContent}
                        title="Davet Linki"
                        trigger="click"
                        placement="top">
                        <span
                            className={
                                invite
                                    ? 'icon-invite icon-action-bar'
                                    : 'icon-invite icon-action-bar'
                            }
                        ></span>
                    </Popover>
                ) : (
                    ''
                )}

                {/* {type == 0 ? (
                    <span
                        onClick={() => setLeftArrow((prevMode) => !prevMode)}
                        className={
                            leftArrow
                                ? "icon-arrow-left icon-action-bar"
                                : "icon-arrow-left icon-action-bar"
                        }
                    ></span>
                ) : (
                    ""
                )} */}
                {/* {type == 0 ? (
                    <span
                        onClick={() => setRightArrow((prevMode) => !prevMode)}
                        className={
                            righArrow
                                ? "icon-arrow-right icon-action-bar"
                                : "icon-arrow-right icon-action-bar"
                        }
                    ></span>
                ) : (
                    ""
                )} */}
                {type == 1 ? (
                    <span
                        onClick={() => {
                            if (buttonDisabled) {
                                raiseYourHand();
                                setBordorColor(4);
                            }
                        }}
                        className={"icon-hand icon-action-bar"}
                        style={{ borderColor: colorHand ? 'limegreen' : '' }}
                    ></span>
                ) : (
                    ""
                )}
            </Space>
        </Card>
    );
};

export default SpecialActionBar;

