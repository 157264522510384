import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Peer } from "peerjs";
import io from 'socket.io-client';
import logo from "./../logo.svg";
import logoa from "./../logoa.svg";
import axios, { Axios } from 'axios';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import '../style.css';
import '../icon.css';
import book from '../icons/book.png';
import RightSider from '../components/RightSider';
import Profile from '../components/Profile';
import { Button, Modal, message, Layout, Switch, Tooltip, Row, Spin } from 'antd';
import LobyGrid from '../components/LobyGrid';
import LobyActionBar from '../components/LobyActionBar';
import { LoadingOutlined } from '@ant-design/icons';

const socket = io(`${process.env.REACT_APP_SOCKET}`); // prod sss
// const socket = io('http://localhost:3030'); // local
// const socket = io('https://back.dact.studio'); // test


const { Header, Sider, Content } = Layout;

type socketData = {
    camera: boolean,
    screen: boolean,
    mic: boolean
}

const Loby = () => {
    let navigate = useNavigate();
    let room = useParams();
    let axiosRoomID = room.roomID;
    var RoomID = 'loby' + room.roomID;
    var user = JSON.parse(localStorage.getItem("user") || "");
    var type = JSON.parse(localStorage.getItem("type") || "");

    const [collapsed, setCollapsed] = useState(false);
    var logged_user = JSON.parse(localStorage.getItem("user") || "");
    const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    const [openMicModal, setOpenMicModal] = useState(false);
    const [openCameraModal, setOpenCameraModal] = useState(false);
    const [openScreenModal, setOpenScreenModal] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const cancelMicModal = () => {
        setOpenMicModal(false);
    }
    const cancelCameraModal = () => {
        setOpenCameraModal(false);
    }
    const cancelScreenModal = () => {
        setOpenScreenModal(false);
    }
    const okMicModal = () => {
        changeMyMic();
        setOpenMicModal(false);
    };
    const okCameraModal = () => {
        changeMyCamera();
        setOpenCameraModal(false);
    };
    const okScreenModal = () => {
        changeMyScreen();
        setOpenScreenModal(false);
    };

    const [myPeer, setMyPeer] = useState<Peer>();
    const [streams, setStreams] = useState<Array<{ key: string, stream: MediaStream, id: string, type: string, peerid: any }>>([]);
    const [name, setName] = useState('');
    const [status, setStatus] = useState('');
    const [userId, setUserId] = useState<number>(0);
    const [myMicId, setMyMicId] = useState<string>('');
    const [myCameraId, setMyCameraId] = useState<string>('');
    const [myScreenId, setMyScreenId] = useState<string>('');
    const [mic, setMic] = useState(false);
    const [camera, setCamera] = useState(false);
    const [screen, setScreen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isStartModalOpen, setIsStartModalOpen] = useState(true);

    const [cameraStream, setCameraStream] = useState<MediaStream | undefined>(undefined);
    const [screenStream, setScreenStream] = useState<MediaStream | undefined>(undefined);
    const [micStream, setMicStream] = useState<MediaStream | undefined>(undefined);
    const [cameraLoading, setCameraLoading] = useState(false);
    const [micLoading, setMicLoading] = useState(false);
    const videoContainerRef = useRef(null);


    const [users, setUsers] = useState([]);
    const [mySocketData, setMySocketData] = useState<socketData>();
    const [gridUsers, setGridUsers] = useState([]);
    const [isRoomConnected, setIsRoomConnected] = useState(false);
    const [clientId, setClientId] = useState<string>('');
    const [tabIndex, setTabIndex] = useState<number | null>(null);
    const [micTooltip, setMicTooltip] = useState(true);
    const [camTooltip, setCamTooltip] = useState(true);

    const [bookTooltip, setBookTooltip] = useState(false);
    const [micTooltipShow, setMicTooltipShow] = useState(false);
    const [camTooltipShow, setCamTooltipShow] = useState(false);
    // useEffect(() => {
    //     const handleBeforeUnload = (event: any) => {
    //       event.preventDefault();
    //       event.returnValue = "Sayfayı yenilemek istediğinizden emin misiniz?";
    //     };

    //     window.addEventListener("beforeunload", handleBeforeUnload);

    //     return () => {
    //       window.removeEventListener("beforeunload", handleBeforeUnload);
    //     };
    //   }, []);

    useEffect(() => {
        // local
        // const myPeer = new Peer({
        //     path: '/peerjs',
        //     host: '/',
        //     port: 3030,
        // });

        // const myPeer = new Peer({ 
        //     path: '/peerjs',
        //     host: 'back.dact.studio',
        // });

        // prod
        const myPeer = new Peer({
            path: '/peerjs',
            host: `${process.env.REACT_APP_PEER}`,
        });

        myPeer.on('open', (id) => {
            console.log(id);
            // peerid = id;
            // setPeer(id);
            setMyPeer(myPeer);
        });

        return () => {
            myPeer.destroy();
        };
    }, []);

    // peer bağlantısı başarılıysa socketdeki rooma bağlan.
    useEffect(() => {
        let nameF;
        let statusF;
        let schoolF;
        let classF;
        let userCorpusId;
        if (myPeer) {
            console.log('myPeerId', myPeer.id);
            if (type != null) {
                if (user) {
                    setUserId(user.id);
                    setName(user.name + ' ' + user.surname);
                    setStatus(user.last_training);
                    nameF = user.name + ' ' + user.surname;
                    userCorpusId = user.id;
                    statusF = user.last_training;
                    schoolF = user.school_name;
                    classF = user.class_id;
                    var typeF = type;
                }
                var browser = navigator.userAgent;

                socket.emit("join-room", RoomID, nameF, typeF, myPeer.id, browser, statusF, schoolF, classF, userCorpusId);
                socket.on('users', (users) => {
                    setUsers(users);
                })
                socket.on('users', (data) => {
                    setGridUsers(data);
                })
                setIsRoomConnected(true);
                setClientId(socket.id);
            }
        }
    }, [myPeer]);

    useEffect(() => {
        Notification.requestPermission().then(function (permission) {
            if (permission === 'granted') {
                console.log('Permission granted');
            }
        });
    }, [])

    useEffect(() => {
        const checkAtiveStatus = async () => {
            const response = await axios.post(`${process.env.REACT_APP_GET_ACTIVE}`, { "id": room.roomID });
            // console.log('amanamanamanamanamans', response.data);
            if (response.data === 1) {
                window.location.href = `https://meet.corpus.study/room/${room.roomID}`;
            }
        }
        checkAtiveStatus();
        setTimeout(() => {
            setBookTooltip(true);
            setMicTooltipShow(true);
            setCamTooltipShow(true);
        }, 500);
    }, [])


    function sendNotification(title: string, options: any) {
        if (!("Notification" in window)) {
            console.log("This browser does not support system notifications");
        }
        else if (Notification.permission === "granted") {
            var notification = new Notification(title, options);
        }
        else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                    var notification = new Notification(title, options);
                }
            });
        }
    }

    useEffect(() => {
        socket.on('clu', data => {
            setMySocketData(data);
            // console.log('mysocketdata', data);
        });
    }, [])

    useEffect(() => {
        socket.on('micNotify', data => {
            setOpenMicModal(true);
            sendNotification('Mikrofon İsteği', { body: data, icon: 'https://corpus.study/favicon-32x32.png' })
        });

        socket.on('cameraNotify', data => {
            setOpenCameraModal(true);
            sendNotification('Kamera İsteği', { body: data, icon: 'https://corpus.study/favicon-32x32.png' })
        });
        socket.on('screenNotify', data => {
            setOpenScreenModal(true);
            sendNotification('Ekran İsteği', { body: data, icon: 'https://corpus.study/favicon-32x32.png' })
        });
        socket.on('teacherNotify', data => {
            messageApi.open({
                type: 'error',
                content: data,
            });
        })
        socket.on('muteTeacherReq', data => {
            console.log(data, 'hoca micx');
            const videoElement = document.getElementById(data) as HTMLVideoElement;
            videoElement.muted = true;
        })
        socket.on('userVoiceClose', data => {
            console.log(data, 'hoca mic');

            const videoElement = document.getElementById(data) as HTMLVideoElement;
            videoElement.muted = true;
        })
        socket.on('userVoiceOpen', data => {
            console.log(data, 'hoca mic');

            const videoElement = document.getElementById(data) as HTMLVideoElement;
            videoElement.muted = false;
        })
    }, [])

    // const showConfirmMic = () => {
    //     confirm({
    //         title: 'Mikrofonunuzu açmanız isteniyor!',
    //         icon: <ExclamationCircleFilled />,
    //         content: 'Öğretmen tarafından mikrofonunuzu açmanız isteniyor. Açmak istiyor musunuz?',
    //         okText: 'Evet!',
    //         cancelText: 'Hayır!',
    //         onOk: async () => {
    //             let stream: MediaStream | undefined;
    //             if (mic == false) {
    //                 setMic(true);
    //                 stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    //                 setMicStream(stream);
    //                 // localVideoRef.current!.srcObject = stream;
    //                 setMyMicId(stream.id);
    //                 socket.emit('updateUserMicId', { streamId: stream.id, client: socket.id });
    //                 if (myPeer) {
    //                     users.forEach((user: any) => {
    //                         if (stream) {
    //                             var local_user = JSON.parse(localStorage.getItem("user") || "");
    //                             var options = { metadata: { "id": local_user.id, "type": "mic", "user_type": type, "peerid": myPeer.id } };
    //                             var call = myPeer.call(user.info.peerid, stream, options);
    //                         }
    //                     })
    //                 }
    //             } else {
    //                 setMic(false);
    //                 setMicStream(undefined);
    //                 if (stream) {
    //                     console.log(1);
    //                     stream.getAudioTracks()[0].stop();
    //                 }
    //                 socket.emit('offMic', { myMicId: myMicId, client: socket.id });
    //             }
    //         },
    //         onCancel() { },
    //     });
    // };

    //mikrofon
    async function changeMyMic() {
        let stream: MediaStream | undefined;
        if (mic == false) {
            setMic(true);
            stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setMicStream(stream);
            // localVideoRef.current!.srcObject = stream;
            setMyMicId(stream.id);
            socket.emit('updateUserMicId', { streamId: stream.id, client: socket.id });
            if (myPeer) {
                users.forEach((user: any) => {
                    if (stream) {
                        var local_user = JSON.parse(localStorage.getItem("user") || "");
                        var options = { metadata: { "id": local_user.id, "type": "mic", "user_type": type, "peerid": myPeer.id } };
                        var call = myPeer.call(user.info.peerid, stream, options);
                    }
                })
            }
        } else {
            setMic(false);
            setMicStream(undefined);
            if (stream) {
                stream.getAudioTracks()[0].stop();
            }
            socket.emit('offMic', { myMicId: myMicId, client: socket.id });
        }
    }

    //ekran
    async function changeMyScreen() {
        let stream: MediaStream | undefined;
        if (screen == false) {
            setScreen(true);
            stream = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    width: { ideal: 768 },
                    height: { ideal: 480 }
                }
            });
            setScreenStream(stream);
            // localVideoRef.current!.srcObject = stream;
            setMyScreenId(stream.id);
            socket.emit('updateUserScreenId', { streamId: stream.id, client: socket.id });
            if (myPeer) {
                var videoContainer = document.getElementById(myPeer.id + 'screen');
                var camContainer = document.getElementById(myPeer.id + 'cam');

                if (videoContainer && camContainer) {
                    var id = stream.id;
                    var newVideo = document.createElement('video');
                    newVideo.id = stream.id;
                    newVideo.srcObject = stream;
                    newVideo.playsInline = true;
                    newVideo.autoplay = false;
                    newVideo.controls = false;
                    newVideo.muted = true;

                    if (videoContainer.childElementCount === 0) {
                        // screen container boş, kamera "screen" container'a yerleştirilebilir
                        await videoContainer.appendChild(newVideo);
                        newVideo.play();
                    } else {
                        // videoContainer'ın child elemanları varsa
                        // tüm child elemanları camContainer'a taşı
                        var childElements = videoContainer.children;
                        // for (var i = 0; i < childElements.length; i++) {
                        //     await camContainer.appendChild(childElements[i]);
                        // }
                        Array.from(childElements).forEach(async (element, i) => {
                            console.log('i', i);
                            await camContainer?.appendChild(element);
                        });
                    }
                    // newVideo'yu videoContainer'a ekle
                    await videoContainer.appendChild(newVideo);
                    newVideo.play();

                }
                users.forEach((user: any) => {
                    console.log(user.info.peerid);
                    if (stream) {
                        var local_user = JSON.parse(localStorage.getItem("user") || "");
                        var options = { metadata: { "id": local_user.id, "type": "screen", "user_type": type, "peerid": myPeer.id } };
                        var call = myPeer.call(user.info.peerid, stream, options);
                    }
                })
            }
        } else {
            setScreen(false);
            setScreenStream(undefined);
            if (stream) {
                stream.getAudioTracks()[0].stop();
            }
            socket.emit('offScreen', { myScreenId: myScreenId, client: socket.id });
        }
    }
    //camera
    const changeMyCamera = async () => {
        let stream: MediaStream | undefined;
        stream = await navigator.mediaDevices.getUserMedia({
            video: {
                width: { exact: 768 },
                height: { exact: 400 }
            }
        });
        if (camera === false) {
            setCamera(true);

            setCameraStream(stream);
            // localVideoRef.current!.srcObject = stream;
            setMyCameraId(stream.id);
            window.localStream = stream;
            await socket.emit('updateUserCameraId', { streamId: stream.id, client: socket.id });
            console.log(myPeer);
            if (myPeer) {
                var camContainer = document.getElementById(myPeer.id + 'cam');
                var videoContainer = document.getElementById(myPeer.id + 'screen');
                var modalContainer = document.getElementById('modalCamera');
                if (isModalOpen) {
                    if (modalContainer) {
                        var id = stream.id;
                        var newVideo = document.createElement('video');
                        newVideo.id = stream.id;
                        newVideo.srcObject = stream;
                        newVideo.playsInline = true;
                        newVideo.autoplay = false;
                        newVideo.controls = false;
                        newVideo.muted = true;
                        await modalContainer.appendChild(newVideo);
                        newVideo.play();
                    }
                }
                if (camContainer) {
                    var id = stream.id;
                    var newVideo = document.createElement('video');
                    newVideo.id = stream.id;
                    newVideo.srcObject = stream;
                    newVideo.playsInline = true;
                    newVideo.autoplay = false;
                    newVideo.controls = false;
                    newVideo.muted = true;

                    if (videoContainer) {
                        if (camContainer && videoContainer.childElementCount === 0) {
                            // screen container boş, kamera "screen" container'a yerleştirilebilir
                            await videoContainer.appendChild(newVideo);
                            newVideo.play();
                        } else {
                            // screen container dolu, kamera "cam" container'a yerleştirilmeli
                            await camContainer.appendChild(newVideo);
                            newVideo.play();

                        }

                    }


                    else {
                        await camContainer.appendChild(newVideo);
                        newVideo.play();
                    }

                }

                users.forEach((user: any) => {
                    console.log(user.info.peerid);
                    if (stream) {
                        var local_user = JSON.parse(localStorage.getItem("user") || "");
                        var options = { metadata: { "id": local_user.id, "type": "camera", "user_type": type, "peerid": myPeer.id } };
                        var call = myPeer.call(user.info.peerid, stream, options);
                    }
                })
            }
        } else {
            setCamera(false);
            // setMyCamera(false);
            setCameraStream(undefined);
            if (stream) {
                stream.getVideoTracks()[0].stop();
            }
            window.localStream.getVideoTracks()[0].stop();

            socket.emit('offCamera', { myCameraId: myCameraId, client: socket.id });
        }
    }

    useEffect(() => {
        socket.on('clients', (clients) => {
            clients.forEach((oneClient: any) => {
                connectBrowsers(oneClient.info.peerid);
                console.log(oneClient.info);
            });
        });
    }, [myPeer]);

    // useEffect(() => {
    //     socket.emit("join-room");

    // }, [])



    async function connectBrowsers(peerId: string) {
        if (myPeer) {
            if (peerId !== myPeer.id) {
                var conn = await myPeer.connect(peerId);
                conn.on('open', function () {
                    console.log('conn open');
                    conn.send('hi');
                });

            }
        }
    }

    useEffect(() => { // baglantinin kabulu ve hi datasinin alip yazdirilmasi
        if (myPeer) {
            myPeer.on('connection', function (conn) {
                conn.on('data', function (data) {
                    // Will print 'hi!'
                    console.log(data);
                });

            });
        }
    }, [myPeer])


    // useEffect(() => {
    //     socket.on('newUser', (data) => {
    //         console.log('newUser')
    //         if (myPeer) {
    //             console.log('my peer var')
    //             if (mySocketData) {
    //                 console.log('my Socket data var');
    //                 if (mySocketData.camera === true) {
    //                     console.log('camera true geldi')
    //                     if (cameraStream) {
    //                         var local_user = JSON.parse(localStorage.getItem("user") || "");
    //                         var options = { metadata: { "id": local_user.id, "type": "camera", "user_type": type, "peerid": myPeer.id } };
    //                         var call = myPeer.call(data.peerid, cameraStream, options);
    //                     }
    //                 }
    //             }
    //             if (screen && screenStream) {
    //                 var local_user = JSON.parse(localStorage.getItem("user") || "");
    //                 var options = { metadata: { "id": local_user.id, "type": "screen", "user_type": type, "peerid": myPeer.id } };
    //                 var call = myPeer.call(data.peerid, screenStream, options);
    //             }
    //             if (mic && micStream) {
    //                 var local_user = JSON.parse(localStorage.getItem("user") || "");
    //                 var options = { metadata: { "id": local_user.id, "type": "mic", "user_type": type, "peerid": myPeer.id } };
    //                 var call = myPeer.call(data.peerid, micStream, options);
    //             }
    //         }
    //     });
    // }, [socket,myPeer])


    useEffect(() => {
        socket.on('newUserMicRequest', data => {
            console.log('new user mic request', micStream);
            if (myPeer) {
                console.log('peer detected')
                if (micStream) {
                    console.log('mic stream detected')
                    var local_user = JSON.parse(localStorage.getItem("user") || "");
                    var options = { metadata: { "id": local_user.id, "type": "mic", "user_type": type, "peerid": myPeer.id } };
                    var call = myPeer.call(data.peerid, micStream, options);
                }
            }
        });
    }, [myPeer, micStream])

    useEffect(() => {
        socket.on('newUserScreenRequest', data => {
            console.log('new user screen request');
            if (myPeer) {
                console.log('peer detected')
                if (screenStream) {
                    console.log('screen stream detected')
                    var local_user = JSON.parse(localStorage.getItem("user") || "");
                    var options = { metadata: { "id": local_user.id, "type": "screen", "user_type": type, "peerid": myPeer.id } };
                    var call = myPeer.call(data.peerid, screenStream, options);
                }
            }
        });
    }, [myPeer, screenStream])

    useEffect(() => {
        socket.on('newUserCameraRequest', data => {
            console.log('new user camera request');
            if (myPeer) {
                console.log('peer detected')
                if (cameraStream) {
                    console.log('camera stream detected')
                    var local_user = JSON.parse(localStorage.getItem("user") || "");
                    var options = { metadata: { "id": local_user.id, "type": "camera", "user_type": type, "peerid": myPeer.id } };
                    var call = myPeer.call(data.peerid, cameraStream, options);
                }
            }
        });
    }, [myPeer, cameraStream])


    useEffect(() => {
        setTimeout(() => {
            if (myPeer) {
                myPeer.on('call', async function (call) {
                    console.log(call.metadata);
                    var user = JSON.parse(localStorage.getItem("user") || "");
                    var type = JSON.parse(localStorage.getItem("type") || "");
                    if (call.metadata.id === user.id) {
                        console.log('Bu çağrı benim çağrım!')
                        // } else if (user.role == "Öğretmen") {
                    } else {
                        call.answer();
                        await call.on('stream', function (remoteStream) {
                            console.log(remoteStream);
                            setStreams([...streams, { key: Date.now().toString(), stream: remoteStream, id: remoteStream.id, type: call.metadata.type, peerid: call.metadata.peerid }]);
                            console.log(streams)
                        });
                    }
                    // } else if (type == 0) {
                    //     call.answer();
                    //     await call.on('stream', function (remoteStream) {
                    //         console.log(remoteStream);
                    //         setStreams([...streams, { key: Date.now().toString(), stream: remoteStream, id: remoteStream.id, type: call.metadata.type, peerid: call.metadata.peerid }]);
                    //         console.log(streams)
                    //     });
                    // // } else if (user.role == "Öğrenci") {
                    // } else if (type == 1) {
                    //     if (call.metadata.user_type == 0) {
                    //         call.answer();
                    //         await call.on('stream', function (remoteStream) {
                    //             console.log(remoteStream);
                    //             setStreams([...streams, { key: Date.now().toString(), stream: remoteStream, id: remoteStream.id, type: call.metadata.type, peerid: call.metadata.peerid }]);
                    //             console.log(streams);
                    //             socket.emit('studentVoiceOn', socket.id);
                    //         });
                    //     } else {
                    //         console.log('gelen ogrenci')
                    //     }
                    // }
                });
            }
        }, 1000);
    }, [myPeer])

    useEffect(() => {
        setIsModalOpen(true);
        setIsStartModalOpen(false);
    }, []);

    useEffect(() => {
        const handleInteraction = () => {
            let allUnmuted = true;
            streams.forEach((stream) => {
                    var micContainer = document.getElementById(stream.peerid + 'mic');
                    var videoElement = micContainer?.querySelector('video');

                    if (videoElement) {
                        allUnmuted = false;

                        videoElement.muted = false; // Sesi aç
                        videoElement.play().then(() => {
                            allUnmuted = true;
                            checkAllUnmuted();
                        }).catch((e) => {
                            console.error('Video oynatılamadı. Hata:', e.message);
                        });
                    }
            });

            function checkAllUnmuted() {
                if (allUnmuted) {
                    window.removeEventListener('click', handleInteraction);
                }
            }

            checkAllUnmuted();
        };

        window.addEventListener('click', handleInteraction);

        return () => {
            window.removeEventListener('click', handleInteraction);
        };
    }, [streams]);

    useEffect(() => { // aramadan sonra stream tetiklenirse video-container divine gelen stream objesini video elemtine atarak append eden method // ayrica aramanin kendi tarafindan mi geldigini kontrol edip ona gore yanitlamasini saglayan method
        Promise.all(
            streams.map(async (stream) => {
                var videoContainer = document.getElementById(stream.peerid + 'screen');
                var camContainer = document.getElementById(stream.peerid + 'cam');
                var micContainer = document.getElementById(stream.peerid + 'mic');

                if (micContainer) {
                    console.log('typeee:', stream.type)
                    if (stream.type === "mic") {
                        var newVideo = document.createElement('video');
                        try {
                            var id = stream.stream.id;
                            newVideo.id = stream.stream.id;
                            newVideo.srcObject = stream.stream;
                            newVideo.playsInline = true;
                            newVideo.autoplay = true;
                            newVideo.controls = false;
                            newVideo.className = "d-none";
                            newVideo.muted = true;
                            await micContainer.appendChild(newVideo);
                            newVideo.play();
                            setTimeout(() => {
                                newVideo.muted = false;
                            }, 500);
                        } catch {
                            newVideo.play();
                        }
                    }
                    else if (stream.type === "camera") {
                        console.log('came girdi');
                        if (camContainer) {
                            var id = stream.stream.id;
                            var newVideo = document.createElement('video');
                            newVideo.id = stream.stream.id;
                            newVideo.srcObject = stream.stream;
                            newVideo.playsInline = true;
                            newVideo.autoplay = false;
                            newVideo.controls = false;
                            newVideo.muted = true;
                            if (videoContainer) {
                                if (videoContainer.childElementCount === 0) {
                                    // screen container boş, kamera "screen" container'a yerleştirilebilir
                                    await videoContainer.appendChild(newVideo);
                                    newVideo.play();
                                } else {
                                    // screen container dolu, kamera "cam" container'a yerleştirilmeli
                                    await camContainer.appendChild(newVideo);
                                    newVideo.play();
                                }
                            }
                            else {
                                await camContainer.appendChild(newVideo);
                                newVideo.play();
                            }
                        }
                    }
                    else if (stream.type === "screen") {
                        console.log('screene girdi');
                        if (videoContainer) {

                            var id = stream.stream.id;
                            var newVideo = document.createElement('video');
                            newVideo.id = stream.stream.id;
                            newVideo.srcObject = stream.stream;
                            newVideo.playsInline = true;
                            newVideo.autoplay = false;
                            newVideo.controls = false;
                            newVideo.muted = true;
                            if (videoContainer.childElementCount === 0) {
                                // screen container boş, kamera "screen" container'a yerleştirilebilir
                                await videoContainer.appendChild(newVideo);
                                newVideo.play();
                            } else {
                                if (camContainer) {
                                    // videoContainer'ın child elemanları varsa
                                    // tüm child elemanları camContainer'a taşı
                                    var childElements = videoContainer.children;
                                    // for (var i = 0; i < childElements.length; i++) {
                                    //     await camContainer.appendChild(childElements[i]);
                                    // }
                                    Array.from(childElements).forEach(async (element, i) => {
                                        console.log('i', i);
                                        await camContainer?.appendChild(element);
                                    });
                                    // Tüm child elemanları kaldır
                                    videoContainer.innerHTML = "";
                                }
                                await videoContainer?.appendChild(newVideo);
                                newVideo.play();
                            }
                        }
                    }
                }
            })
        );
    }, [streams]);

    //ekran veya kameranin kapandigini bildiren method
    useEffect(() => {
        socket.on('offMediaRequest', data => {
            console.log(data);
            setStreams(streams.filter(item => item.id !== data))
            const videoElement = document.getElementById(data) as HTMLVideoElement;
            if (videoElement) {
                console.log('1');
                const stream = videoElement.srcObject as MediaStream;
                if (stream) {
                    stream.getVideoTracks()[0].stop();
                }
                videoElement.src = '';
                videoElement.remove();
                // window.localStream.getVideoTracks()[0].stop();
            }
        });
    }, [])

    //mikrofonun kapandigini bildiren method
    useEffect(() => {
        socket.on('offMicRequest', data => {
            console.log(data);
            setStreams(streams.filter(item => item.id !== data))
            const videoElement = document.getElementById(data) as HTMLVideoElement;
            if (videoElement) {
                console.log('1');
                const stream = videoElement.srcObject as MediaStream;
                if (stream) {
                    stream.getAudioTracks()[0].stop();
                }
                videoElement.src = '';
                videoElement.remove();
            }
        });
    }, [])

    async function startLesson() {
        console.log('aaaabbb', room.roomID);

        const apiData = {
            "id": room.roomID,
            "active": 1
        };
        const request = await axios.post(`${process.env.REACT_APP_ACTIVE}`, apiData);
        console.log(request.data);
        socket.emit('startLesson');
    }

    // async function localCam() {
    //     var modalContainer = document.getElementById('modalCamerax');
    //     let stream: MediaStream | undefined;
    //     stream = await navigator.mediaDevices.getUserMedia({
    //         video: {
    //             width: { exact: 320 },
    //             height: { exact: 180 }
    //         }
    //     });
    //     if (camera === true) {
    //         console.log('b');
    //         if (modalContainer) {
    //             console.log('a')
    //             var id = stream.id;
    //             var newVideo = document.createElement('video');
    //             newVideo.id = stream.id;
    //             newVideo.srcObject = stream;
    //             newVideo.playsInline = true;
    //             newVideo.autoplay = false;
    //             newVideo.controls = false;
    //             newVideo.muted = true;
    //             await modalContainer.appendChild(newVideo);
    //             newVideo.play();
    //         }
    //     }
    // }
    // useEffect(() => {
    //     if (videoRef.current && cameraStream) {
    //       videoRef.current.srcObject = cameraStream;
    //       videoRef.current.play();
    //     }
    //   }, [cameraStream]);

    useEffect(() => {
        socket.on('startLessonRequest', data => {
            if (type == 1) {
                setIsStartModalOpen(true);
            }
            const videoContainer = document.getElementById('modalCamerax') as HTMLDivElement;
            var newVideo = document.createElement('video');
            if (cameraStream) {
                console.log('asxasx');
                var id = cameraStream.id;
                newVideo.id = cameraStream.id;
                newVideo.srcObject = cameraStream;
                newVideo.playsInline = true;
                newVideo.autoplay = true;
                newVideo.controls = false;
                newVideo.className = "d-none";
                newVideo.muted = true;
                if (videoContainer) {
                    videoContainer.appendChild(newVideo);
                }
                newVideo.play();
            }
            if (type == 1) {
                setTimeout(function () {
                    window.location.href = `https://meet.corpus.study/room/${room.roomID}`;
                }, 3000);
            }
            else {
                window.location.href = `https://meet.corpus.study/room/${room.roomID}`;
            }

        })
    }, [isStartModalOpen, cameraStream])

    const switchCamera = async (checked: boolean) => {
        setCamTooltipShow(!camTooltipShow);
        setCameraLoading(true);
        const modalElement = document.getElementById('modalCamera');
        if (modalElement) {
            modalElement.innerHTML = '';
        }
        await changeMyCamera();
        setTimeout(function () {
            setCameraLoading(false);
            setCamTooltip(false);
        }, 1500);

        // handleSecondChange(checked);
    };

    const switchMic = async (checked: boolean) => {
        setMicTooltipShow(!micTooltipShow);
        setMicLoading(true);
        await changeMyMic();
        setTimeout(function () {
            setMicLoading(false);
            setMicTooltip(false);
        }, 1500);
        // handleSecondChange(checked);
    };

    const handleOk = async () => {
        await setMicTooltipShow(false);
        await setCamTooltipShow(false);
        await setBookTooltip(false);
        await setMicTooltip(false);
        await setCamTooltip(false);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        window.location.href = `https://app.corpus.study`;
    };

    return (
        <Layout style={{
            height: '100vh',
            background: "#292B3B"
        }}>
            {contextHolder}

            {/* <iframe src="https://corpus-test.dact.studio" height={600}></iframe> */}
            <Header style={{ padding: '0', background: "#292B3B", display: 'block', marginBottom: '5px', height: '45px' }}>
                <div style={{ marginLeft: '50px', paddingRight: '50px', display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ display: 'flex', alignItems: 'center', height: '42px', marginTop: '10px' }}>
                        <img style={{ display: 'inline', height: '38px', objectFit: 'contain' }} src={logo} alt="Corpus Logo" />
                        <span
                            className="icon-observer-enable icon-height trigger" style={{ marginLeft: '50px', marginTop: '10px' }}>
                        </span>
                    </span>
                    <span style={{ color: '#fff', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: logged_user.group_name }} />
                    <span style={{ color: '#fff' }}>
                        <span
                            onClick={() => setCollapsed(!collapsed)}
                            className={!collapsed ? "icon-chat-open icon-height trigger" : "icon-chat-close icon-height trigger"} style={{ float: 'left' }}>
                        </span>
                        <span>
                            <Profile name={user.name} surname={user.surname} />
                        </span>
                    </span>
                </div>
            </Header>

            <Layout className="site-layout" style={{ background: '#292B3B' }}>
                <Modal
                    centered={true}
                    title={<div style={{ color: "white", alignItems: 'center', textAlign: "center" }}><h2>Öğretmeniniz Dersi Başlatıyor..</h2><h3 style={{ fontWeight: "normal" }}>Birazdan Derse Katılacaksınız</h3> <Spin indicator={antIcon} /></div>}
                    open={isStartModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={"100%"}
                    maskClosable={false}
                    closable={false}
                    visible={true}
                    className={'welcome-modal'}
                    maskStyle={{ backgroundColor: "rgb(41, 43, 59)" }}
                    footer={null}
                    // maskStyle={{ backgroundColor: "rgb(27, 30, 43)", opacity: "0.7" }}
                    bodyStyle={{ backgroundColor: "rgb(27, 30, 43)", borderRadius: '10px', padding: '10px', width: "50%" }}
                    style={{ color: "#292B3B" }}
                    keyboard={false}
                >
                    <div style={{ alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", backgroundColor: "rgb(27, 30, 43)", padding: "30px" }}>
                        <div ref={videoContainerRef} style={{ height: "350px", width: "90%", backgroundImage: "url('https://i.hizliresim.com/rwydt98.png')", backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat', borderRadius: "10px", position: 'relative' }} id="modalCamerax">
                        </div>
                    </div>
                </Modal>
                <Modal
                    centered={true}
                    title={<div style={{ color: "white", alignItems: 'center', textAlign: "center" }}><h2 style={{ fontWeight: "normal" }}>Merhaba <span style={{ fontWeight: "bold" }}>{logged_user.name} {logged_user.surname},</span></h2></div>}
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={"100%"}
                    maskClosable={false}
                    closable={false}
                    visible={true}
                    className={'welcome-modal'}
                    maskStyle={{ backgroundColor: "rgb(41, 43, 59)" }}

                    // maskStyle={{ backgroundColor: "rgb(27, 30, 43)", opacity: "0.7" }}
                    bodyStyle={{ backgroundColor: "rgb(27, 30, 43)", borderRadius: '10px', padding: '10px', width: "70%" }}
                    style={{ color: "#292B3B" }}
                    keyboard={false}
                    footer={
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <Button className='connect-button-modal' style={{ backgroundColor: "rgb(27, 30, 43)", color: 'white', border: 0, marginRight: '30px', width: '100px' }} onClick={handleCancel}>Çıkış</Button>

                            <Button style={{ backgroundColor: "rgb(27, 30, 43)", border: 0, color: "white", }} onClick={handleOk}>
                                Derse Bağlan
                            </Button>
                        </div>
                    }
                >
                    <img src={logoa} alt="Corpus Logo" style={{
                        position: 'absolute',
                        top: 15,
                        left: 0,
                    }} />

                    <div style={{ alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", backgroundColor: "rgb(27, 30, 43)", padding: "30px" }}>
                        <div style={{ height: "350px", width: "90%", backgroundImage: "url('https://i.hizliresim.com/rwydt98.png')", backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat', borderRadius: "10px", position: 'relative' }} id="modalCamera"></div>
                    </div>

                    <div style={{ display: "flex", marginLeft: '7.5%', marginTop: "0px", marginBottom: "25px" }}>
                        <Tooltip placement="topRight" color={'rgb(41, 43, 59)'} title="Lütfen mikrofonunu aktifleştir" open={micTooltipShow}>
                            <div
                                className={
                                    'icon-mic-enable icon-action-bar'
                                }
                            ></div>
                        </Tooltip>
                        <Switch checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />} className={"welcome-modal-switch"} style={{ margin: "15px" }} onChange={switchMic} loading={micLoading}></Switch>
                        <Tooltip placement="topLeft" color={'rgb(41, 43, 59)'} title="Lütfen kameranı aktifleştir" open={camTooltipShow}>

                            <div
                                className={
                                    'icon-cam-enable icon-action-bar'
                                }
                                style={{ marginLeft: '40px' }}
                            ></div>
                        </Tooltip>
                        <Switch checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />} className={"welcome-modal-switch"} style={{ margin: "15px" }} onChange={switchCamera} loading={cameraLoading} ></Switch>

                        <div className='ax' style={{ marginLeft: "auto", marginRight: "7.5%" }}>
                            <Tooltip placement="topRight" color={'rgb(41, 43, 59)'} title="Lütfen kitabınız masanızın üstünde hazır olsun." open={bookTooltip}>
                                <img style={{ display: 'inline' }} src={book} height={55} alt="Corpus Logo" />
                            </Tooltip>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="Mikrofon açmanız isteniyor!"
                    open={openMicModal}
                    onOk={okMicModal}
                    onCancel={cancelMicModal}
                    okText="Evet!"
                    cancelText="Hayır!"
                >
                    <p>Öğretmen tarafından mikrofonunuzu açmanız isteniyor.</p>
                    <p>Açmak istiyor musunuz?</p>
                </Modal>

                <Modal
                    title="Kamera açmanız isteniyor!"
                    open={openCameraModal}
                    onOk={okCameraModal}
                    onCancel={cancelCameraModal}
                    okText="Evet!"
                    cancelText="Hayır!"
                >
                    <p>Öğretmen tarafından kameranızı açmanız isteniyor.</p>
                    <p>Açmak istiyor musunuz?</p>
                </Modal>

                <Modal
                    title="Ekran paylaşmanız isteniyor!"
                    open={openScreenModal}
                    onOk={okScreenModal}
                    onCancel={cancelScreenModal}
                    okText="Evet!"
                    cancelText="Hayır!"
                >
                    <p>Öğretmen tarafından ekranınızı paylaşmanız isteniyor.</p>
                    <p>Paylaşmak istiyor musunuz?</p>
                </Modal>

                <Content
                    style={{
                        margin: '10px',
                        height: '80vh',
                        background: "#292B3B",
                    }}>
                    <div className='content' style={{ background: '#1B1E2B', borderRadius: '10px' }}>
                        {/* <div className='content-screens' style={{ paddingTop: '10px' }}> */}
                        <LobyGrid users={gridUsers} collapsed={!collapsed} type={type} clientId={clientId} />
                        <div className="video-container">
                        </div>
                        {/* </div> */}
                    </div>
                    <div>
                    </div>
                    <div className='userPanel'>
                        <LobyActionBar clientId={clientId} socket={socket} changeMessage={() => setCollapsed(!collapsed)} mic={mic} screen={screen} camera={camera} changeMyCamera={changeMyCamera} changeMyMic={changeMyMic} changeMyScreen={changeMyScreen} startLesson={startLesson} />
                    </div>
                </Content>
                <RightSider users={users} type={type} userIdDb={userId} socket={socket} collapsed={!collapsed} clientId={clientId} room={room} name={name} changeMyCamera={changeMyCamera} special={1}></RightSider>
            </Layout>
        </Layout>
    )
}

export default Loby