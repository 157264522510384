import React, { useState, useEffect, useRef } from 'react'
import { Space, notification, Tabs, TabsProps, Button } from 'antd';
import User from '../components/User';
import TextArea from 'antd/es/input/TextArea';
import Sider from 'antd/es/layout/Sider';
import { Socket } from 'socket.io-client';
import ChatAuthUsers from './ChatAuthUsers';
import ChatUsers from './ChatUsers';
import IconParticipants from '../icons/message.png'
import AllUsers from './AllUsers';
import axios from 'axios';

interface Props {
    socket: Socket,
    clientId: string,
    room: any,
    type: number,
    users: any,
    collapsed: boolean
    name: string
    changeMyCamera: any,
    userIdDb: number,
    special: number
}


const RightSider: React.FC<Props> = ({ socket, clientId, userIdDb, room, type, users, collapsed, name, changeMyCamera, special }) => {
    var user = JSON.parse(localStorage.getItem("user") || "");
    const [message, setMessage] = useState('');
    const [messagesRecieved, setMessagesReceived] = useState<any[]>([]);
    const [api, contextHolder] = notification.useNotification();
    const messagesEndRef = useRef<any>(null);

    //chata mesaj gonderen method
    async function sendMessage() {
        if (message.length > 6000) {
            alert('En fazla 6000 karakter girilebilir');
        } else {
            if (message !== '') {
                const __createdtime__ = Date.now();
                axios.post('https://app.corpus.study/api/chat-log', {
                    user_id: userIdDb,
                    // user_fullname: name,
                    message: message,
                    group_id: room.roomID,
                    send_to: 0,
                }).then(response => {
                    console.log(response);
                }).catch(error => {
                    console.error(error);
                });

                await socket.emit('sendMessage', { name, room, message, __createdtime__, userIdDb });


                setMessage('');

                // console.log('gonderdigim yer');
            } else {
                alert('boş mesaj gönderemesiniz')
            }
        }
    };
    useEffect(() => {
        axios.get(`https://app.corpus.study/api/chat-log/${room.roomID}`)
            .then(response => {
                let newMessages: any[] = [];
                console.log(response.data);
                response.data.forEach((message: { message: any; user: any; created_at: any; user_id: any; }) => {
                    let newMessage = {
                        message: message.message,
                        username: message.user.name + ' ' + message.user.surname,
                        __createdtime__: message.created_at,
                        clientId: message.user_id
                    }
                    newMessages.push(newMessage);
                });
                setMessagesReceived([...messagesRecieved, ...newMessages]);

            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    // chatten gelen mesaji karsilayan method
    useEffect(() => {
        socket.on('receive_message', data => {
            let newMessage = {
                message: data.message,
                username: data.name,
                __createdtime__: data.__createdtime__,
                clientId: data.userIdDb
            };
            setMessagesReceived(messagesRecieved => [...messagesRecieved, newMessage]);
            scrollToBottom();
        });
        return () => {
            socket.off('receive_message');
        }
    }, [socket])

    // enter ile mesaj göndermek için
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            sendMessage();
            scrollToBottom();
        }
    };

    // öğretmenin öğrenciye göndereceği istekler
    function changeMic(id: number) {
        socket.emit('changeMic', id);
    }
    function changeScreen(id: number) {
        socket.emit('changeScreen', id);
    }

    function sendPrivateMessage(id: number) {
        socket.emit('sendPrivateMessage', id);
    }
    function changeCamera(id: number) {
        socket.emit('changeCamera', id);
    }
    function changeSound(data: any) {
        socket.emit('changeSound', data);
    }
    function kick(id: number) {
        socket.emit('kick', id);
    }
    function sendAllStudentMicReq() {
        socket.emit('allStudentMicRequest');
    }
    function teacherMicMuteAllStudents() {
        socket.emit('teacherMicMuteAllStudents');
    }

    function sendNotification(title: string, options: any) {
        console.log(1);
        if (!("Notification" in window)) {
            console.log("This browser does not support system notifications");
        }
        else if (Notification.permission === "granted") {
            console.log('yes');
            var notification = new Notification(title, options);
        }
        else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                    var notification = new Notification(title, options);
                }
            });
        }
    }
    function scrollToBottom() {
        setTimeout(function () {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }, 100);
    }
    useEffect(() => {


        socket.on('kickNotify', data => {
            alert(data);
            window.location.href = 'https://app.corpus.study';
            sendNotification('Dersten çıkartıldınız!', { body: data, icon: 'https://corpus.study/favicon-32x32.png' })
        });
    }, [])

    // user type göre sadece öğretmene ulaşacak el kaldırma bildirimi methodu
    useEffect(() => {
        socket.on('notifyHand', data => {
            var notify = data + ' ' + 'isimli öğrenci söz hakkı istiyor.'
            if (type === 0 || type === 2) {
                api['warning']({
                    message: `Söz hakkı isteniyor!`,
                    description: notify,
                });
            }
        })
    }, [type])




    const items: TabsProps['items'] = [
        {
            key: '1',
            label: (<span className='chat-span d-inline'> <span className='icon-message icon-sider' /> Sohbet</span>),
            children: (
                <div className='chat-container' >
                    <div className='messages-box' ref={messagesEndRef}>
                        {messagesRecieved.map((msg: { username: string, message: string, __createdtime__: any, clientId: any }, i) => (
                            userIdDb === msg.clientId ? <ChatAuthUsers hour={msg.__createdtime__} message={msg.message} key={i} title={msg.username} /> : <ChatUsers title={msg.username} hour={msg.__createdtime__} message={msg.message} />
                        ))}
                    </div>
                    <div className="send-box" style={{ display: "flex" }}>
                        <TextArea
                            style={{ width: 250, color: 'white', border: '0' }}
                            autoSize={{ minRows: 2, maxRows: 2 }}
                            className='message-input'
                            placeholder='Mesaj Gönder...'
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                            id="message"
                            name="message"
                            onKeyDown={handleKeyDown}
                        />
                        <button type="submit" style={{ height: '0px', padding: '0px' }} className="send-button" onClick={sendMessage}><span className="icon-message-send"><span className="path1"></span><span className="path2"></span></span></button>
                    </div>
                </div>
            ),
        },
        {
            key: '2',
            label: (<span className='chat-span d-inline'> <img src={IconParticipants} alt="" width={30} height={18} /> {type == 0 || type == 2 ? 'Katılımcılar' : special === 3 ? 'Katılımcılar' : 'Dersteki Öğrenciler'} </span>),
            children: (<div className='messages-box' >
                {type === 0 || type === 2 ? <AllUsers sendAllStudentMicReq={sendAllStudentMicReq} teacherMicMuteAllStudents={teacherMicMuteAllStudents} /> : ''}
                {users.map((consumer: { info: { id: number, name: string, mic: boolean, sound: boolean, camera: boolean, screen: boolean, type: number } }) => (
                    <div className="admin-panel" key={consumer.info.id}>
                        <User name={consumer.info.name} voice={consumer.info.sound} mic={consumer.info.mic} cam={consumer.info.camera} screen={consumer.info.screen} sendPrivateMessage={() => sendPrivateMessage(consumer.info.id)} changeMic={() => changeMic(consumer.info.id)} changeScreen={() => changeScreen(consumer.info.id)} changeCamera={() => changeCamera(consumer.info.id)} changeSound={() => changeSound({ "id": consumer.info.id, "teacher_id": socket.id })} kick={() => kick(consumer.info.id)} client_id={clientId} user_id={consumer.info.id} userType={consumer.info.type} type={type} isLoby={special===1? true:false}/>
                    </div>
                ))}
            </div>

            ),
        },

    ];
    //     const [siderWidth, setSiderWidth] = useState(344); // daha kucuk bir sider. simdilik silme
    //   const siderRef = useRef<HTMLDivElement>(null); // Element referansını HTMLDivElement olarak belirtin

    //   useEffect(() => {
    //     const handleResize = () => {
    //       if (siderRef.current) {
    //         const contentWidth = siderRef.current.offsetWidth;
    //         setSiderWidth(contentWidth);
    //       }
    //     };

    //     handleResize();
    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //       window.removeEventListener('resize', handleResize);
    //     };
    //   }, []);


    return (
        <>
            {contextHolder}
            {/* <Sider // daha kucuk bir sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                width={siderWidth}
                collapsedWidth={0}
                style={{ background: '#292B3B' }}
            >
                <Space direction="vertical">
                    <div ref={siderRef}>
                        <Tabs defaultActiveKey="1" items={items} style={{
                            margin: '0px 20px',
                        }} />
                    </div>
                </Space>
            </Sider> */}
            <Sider trigger={null} collapsible collapsed={collapsed} width={340} collapsedWidth={0} style={{ background: '#292B3B', minWidth: '290px', width: 'auto' }}>
                <Space direction="vertical">
                    {/* <Button onClick={sendAllStudentMicReq}>Tüm öğrencilere mik isteği</Button>
                    <Button onClick={teacherMicMuteAllStudents}>Tüm öğrencilere mikrofonumu kapat</Button> */}
                    <Tabs defaultActiveKey="1" items={items} style={{
                        margin: '0px 20px',
                    }} />
                </Space>
            </Sider>
        </>
    )
}

export default RightSider