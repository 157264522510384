import { useState } from 'react';
import { Avatar, List } from 'antd';
import '../icon.css';
import '../style.css';

interface Props {
    message: string,
    hour: string,
    title: string,
}

function formatDateFromTimestamp(timestamp: any) {
    const date = new Date(timestamp);
    return date.toLocaleString();
}

const ChatAuthUsers: React.FC<Props> = ({ message, hour, title }) => {
    const [showFullMessage, setShowFullMessage] = useState(false);

    const shortMessage = message.slice(0, 850);
    const fullMessage = message.slice(850);

    const handleReadMore = () => {
        setShowFullMessage(true);
    };

    return (
        <div className='chat-auth-users'>
            <List.Item className='chat-auth-item'>
                <div className='messageboxp'>
                    <div className='messagebox'>
                        {showFullMessage ? message : shortMessage}
                        {message.length > 850 && !showFullMessage && (
                            <button onClick={handleReadMore}>Devamını Oku</button>
                        )}
                        {showFullMessage && (
                            <div>{fullMessage}</div>
                        )}
                    </div>
                    <span>
                        {
                            <Avatar style={{ marginLeft: '5px', marginBottom: '5px',marginRight:'4px' }}>
                                <div className='avatar-char'>
                                    {title.charAt(0)}
                                </div>
                            </Avatar>
                        }
                    </span>
                </div>
                <span className='hour'>{formatDateFromTimestamp(hour)}</span>
            </List.Item>
        </div>
    );
};

export default ChatAuthUsers;
