import React from 'react';
import { Avatar, Card } from 'antd';
import '../icon.css';
import '../style.css';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Space } from 'antd';


const { Meta } = Card;
interface Props {
    name: String,
    surname: String,
}
const items: MenuProps['items'] = [
    {
        key: '1',
        label: (
            <a target="_blank" rel="noopener noreferrer" href="https://app.corpus.study/login-site">
                Corpus'a Git
            </a>
        ),
    },
];
const Profile: React.FC<Props> = ({ name, surname }) => {
    return (
        <>
            <Avatar style={{ marginRight: '10px', marginBottom: '5px' }} shape="square">
                <div style={{ fontSize: '20px' }}>
                    {name.charAt(0)}
                </div>
            </Avatar>
            {/* <Avatar src="https://joesch.moe/api/v1/random" style={{ marginRight: '10px', marginBottom: '5px' }} shape="square" /> */}
            <span >{name} {surname}</span>
            <Dropdown menu={{ items }} placement="bottom">
                <button>▼</button>
            </Dropdown>
        </>
    )
};

export default Profile;