import React, { useState, useEffect } from 'react'
import { Card, Space, Popover, Input, Tooltip } from 'antd';
import '../icon.css';
import '../style.css';
import { Socket } from 'socket.io-client';
import Peer from 'peerjs';
import { CopyOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom';
import { start } from 'repl';
import IconStartLesson from '../icons/icon-start-lesson.png';


const { Meta } = Card;

interface Props {
    changeMessage: any;
    changeMyScreen: any;
    changeMyMic: any;
    socket: Socket;
    clientId: string;
    changeMyCamera: any;
    mic: any;
    screen: any;
    camera: any;
    startLesson: any;
}

declare global {
    interface Window {
        localStream: any; // 👈️ turn off type checking
    }
}

const LobyActionBar: React.FC<Props> = ({
    changeMessage,
    changeMyCamera,
    changeMyScreen,
    changeMyMic,
    clientId,
    socket,
    mic,
    screen,
    camera,
    startLesson
}) => {
    var type = JSON.parse(localStorage.getItem('type') || '');
    const [grid, setGrid] = useState(true);
    let params = useParams();
    // Add states for each button's disabled status
    const [cameraDisabled, setCameraDisabled] = useState(false);
    const [screenDisabled, setScreenDisabled] = useState(false);
    const [micDisabled, setMicDisabled] = useState(false);
    const [callEnd, setCallEnd] = useState(true);
    const [message, setMessage] = useState(true);
    const [settings, setSettings] = useState(true);
    const [invite, setInvite] = useState(true);
    const [leftArrow, setLeftArrow] = useState(true);
    const [righArrow, setRightArrow] = useState(true);
    const [copied, setCopied] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    // meet cagridan ayrilam methodu
    function leave() {
        window.location.href = 'https://app.corpus.study';
    }
    function raiseYourHand() {
        socket.emit('raiseYourHand', clientId);
    }

    // Helper function to handle button disabling
    const handleClick = (
        eventHandler: () => void,
        setDisabled: (disabled: boolean) => void
    ) => {
        eventHandler();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1760);
        setTimeout(() => {
            setButtonDisabled(true);
        }, 1200);
    };

    const copyToClipboard = () => {
        console.log('params', params);
        const inviteLink = `https://app.corpus.study/app/connect-meet/${params.roomID}`;
        navigator.clipboard.writeText(inviteLink);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 500); // 2 saniye sonra yeşil border'ın kaybolmasını sağlayacak
    };


    const inviteContent = (
        <div style={{ display: 'flex' }}>
            <Input
                id="invite-link"
                value={`https://app.corpus.study/app/connect-meet/${params.roomID}`}
                readOnly
                className='input-link'
                style={{
                    width: '100%',
                    borderColor: copied ? 'limegreen' : undefined // copied durumuna göre border rengini değiştir
                }}
            />
            <CopyOutlined onClick={copyToClipboard} style={{ marginTop: '10px', marginLeft: '10px', color: '#fff' }} />
        </div>
    );

    const [colorCam, setColorCam] = useState(false);
    const [colorMic, setColorMic] = useState(false);


    const setBordorColor = (id: number) => {
        if (id === 1) {
            setColorCam(true);
            setTimeout(() => {
                setColorCam(false);
            }, 200);
        }

        else if (id === 3) {
            setColorMic(true);
            setTimeout(() => {
                setColorMic(false);
            }, 200);
        }

    };


    return (
        <Card className="action-bar" style={{ background: '#292B3B', textAlign: 'center' }}>
            <Space size={15}>
                <span
                    onClick={() => {
                        if (buttonDisabled) {
                            setButtonDisabled(false);
                            handleClick(changeMyCamera, setCameraDisabled);
                            setBordorColor(1);
                        }
                    }}
                    className={
                        camera
                            ? 'icon-cam-enable icon-action-bar'
                            : 'icon-cam-disable-white icon-action-bar'
                    }
                    style={{ cursor: 'pointer', pointerEvents: cameraDisabled ? 'none' : 'auto', borderColor: colorCam ? 'limegreen' : '' }}
                ></span>
                {/* <span
                    onClick={() => handleClick(changeMyScreen, setScreenDisabled)}
                    className={
                        screen
                            ? 'icon-screen-enable icon-action-bar'
                            : 'icon-screen-disable icon-action-bar'
                    }
                    style={{ cursor: 'pointer', pointerEvents: screenDisabled ? 'none' : 'auto' }}
                ></span> */}
                <span
                    onClick={() => {
                        if (buttonDisabled) {
                            setButtonDisabled(false);
                            handleClick(changeMyMic, setMicDisabled);
                            setBordorColor(3);
                        }
                    }}
                    className={
                        mic
                            ? 'icon-mic-enable icon-action-bar'
                            : 'icon-mic-disable-white icon-action-bar'
                    }
                    style={{ cursor: 'pointer', pointerEvents: micDisabled ? 'none' : 'auto', borderColor: colorMic ? 'limegreen' : '' }}
                ></span>
                <span onClick={leave} className="icon-call-end icon-action-bar-call"></span>
                <span
                    onClick={changeMessage}
                    className={"icon-message icon-action-bar"}
                ></span>
                {type == 0 || type == 2 ? (
                    <Popover
                        content={inviteContent}
                        title="Davet Linki"
                        trigger="click"
                        placement="top">
                        <span
                            className={
                                invite
                                    ? 'icon-invite icon-action-bar'
                                    : 'icon-invite icon-action-bar'
                            }
                        ></span>
                    </Popover>
                ) : (
                    ''
                )}
                {type == 0 || type == 2 ? (
                    <Tooltip placement="topLeft" title={'Dersi Başlat'}>
                        <span
                            onClick={startLesson}
                            className={"icon-action-bar"}
                        ><img src={IconStartLesson} alt="" width={36} height={32} /></span>
                    </Tooltip>
                ) : (
                    ""
                )}

                {type == 1 ? (
                    <div style={{
                        padding: '10px',
                        border: '1px solid rgb(41, 43, 59)',
                        backgroundColor: 'rgb(27, 30, 43)',
                        color: '#fff',
                        borderRadius: '10px',
                        width: 'fit-content'
                    }}>
                        Öğretmeninizi ve Grubun <br /> tamamlanmasını bekleyin.
                    </div>

                ) : (
                    ""
                )}
            </Space>
        </Card>
    );
};

export default LobyActionBar;

