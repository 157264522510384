import React, { useEffect, useState } from 'react';
import { Avatar, Card, Col, Row, Space, Popconfirm, Tooltip } from 'antd';
import '../icon.css';
import '../style.css';


const { Meta } = Card;
interface Props {
    sendAllStudentMicReq: any
    teacherMicMuteAllStudents: any

}
const AllUsers: React.FC<Props> = ({ sendAllStudentMicReq, teacherMicMuteAllStudents }) => {

    const [voice, setVoice] = useState(false);


    const allMicReq = () => {
        setVoice(!voice)
        if (voice === true) {
            teacherMicMuteAllStudents();
        }
        else {
            sendAllStudentMicReq();
        }
    };

    return (
        < Card className='user-card' style={{ background: '#292B3B' }}>
            <Row justify="space-between">
                <Col span={14}>
                    <Meta className='user-card-box'
                        avatar={<Avatar >
                            <div className='avatar-char'>
                                T
                            </div>
                        </Avatar>}
                        title={<span style={{ color: '#C1C1C6' }}>Tüm Kullanıcılar</span>}
                    />
                </Col>
                <Col span={5} style={{justifyContent:'end'}}>
                    <Space size={5}>
                        {/* <span
                            onClick={sendPrivateMessage}
                            className={"icon-message icon-height"} >
                        </span> */}
                        <Tooltip placement="topLeft" title={'Tüm Öğrencilerden Mikrofonlarını Açmalarını İste'}>
                            <span
                                onClick={sendAllStudentMicReq}
                                className={"icon-mic-enable icon-height"} >
                            </span>
                        </Tooltip>
                        <Tooltip placement="topLeft" title={'Tüm Öğrencilere Sesimi Kapat'}>
                            <span
                                onClick={teacherMicMuteAllStudents}
                                className={"icon-voice-disable icon-height"} >
                            </span>
                        </Tooltip>

                    </Space>
                </Col>
            </Row>
        </Card >
    )
};

export default AllUsers;