import React,{useEffect} from 'react'

export const Redirect = () => {
    useEffect(() => {
      window.location.href = "https://app.corpus.study/login-meet"
    }, [])
    
  return (
    <div>Yonlendiriliyor</div>
  )
}
