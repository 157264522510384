import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import '../style.css'

import { Layout } from 'antd';
import Screen from './Screen';

interface usersArray {
    userId: string,
    info: {
        peerid: any;
        id: number,
        name: string,
        mic: boolean,
        sound: boolean,
        camera: boolean,
        screen: boolean,
        type: number,
        status: string,
        class: number,
        school: string,
        userCorpusId:number,
    }
}

interface Props {
    users: Array<usersArray>,
    collapsed: boolean,
    type: number,
    clientId: any,
}

const LobyGrid: React.FC<Props> = ({ users, collapsed, type, clientId }) => {
    console.log('sasa', users);
    const [length, setLength] = useState(0);

    useEffect(() => {
        setLength(users.length)
        console.log('lenght', length)
    }, [users])
    return (
        <Layout className={'grid-screens'}>
            {length <= 1 ?
                <>
                    <Row style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                        {(users.map((user) => (
                            <Col span={12} style={{ height: '65%' }} key={user.info.id}>
                                <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                            </Col>
                        )))}
                    </Row>
                </>
                :
                length <= 2 ?
                    <>
                        <Row style={{ height: '100%', display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            {(users.map((user) => (
                                <Col span={12} style={{ height: '65%' }} key={user.info.id}>
                                    <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                </Col>
                            )))}
                        </Row>
                    </>
                    :
                    length <= 4 ?
                        <Row style={{ height: '100%', marginTop: '10px' }}>
                            {(users.map((user) => (
                                <Col span={12} key={user.info.id}>
                                    <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                </Col>
                            )))}
                        </Row>
                        :
                        users.length <= 9 ?
                        <Row style={{ height: '100%', marginTop: '10px' }}>
                            {(users.map((user) => (
                                <Col span={8} key={user.info.id}>
                                   <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                                </Col>
                            )))}
                        </Row>
                        :
                        users.length <= 15 ?
                        <Row style={{ height: '100%', marginTop: '10px' }}>
                        {(users.map((user) => (
                            <Col span={6} key={user.info.id}>
                               <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                            </Col>
                        )))}
                    </Row>
                    :
                    <Row style={{ height: '100%', marginTop: '10px' }}>
                    {(users.map((user) => (
                        <Col span={4} key={user.info.id}>
                           <Screen mic={user.info.mic} id={user.info.userCorpusId} title={user.info.name + ' ' + (user.info.class === 4 || user.info.class == null ? '' : user.info.class + '.')} screenCount={length} peerID={user.info.peerid} collapsed={collapsed} status={user.info.status} type={type} />
                        </Col>
                    )))}
                </Row>
            }
        </Layout >
    );
};

export default LobyGrid;